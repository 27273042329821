import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "./store";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './style/index.scss';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store, key)
app.use(router)
app.use(ElementPlus)

const modules = require.context('./components', true, /\.ts/)
modules.keys().forEach(path => {
  app.use(modules(path).default)
})

app.mount("#app");
