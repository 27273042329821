import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { IMenu, IUserInfo } from '@/api/types/common'
import createPersistedState from 'vuex-persistedstate'

export interface State {
  isCollapse: boolean,
  menus: IMenu[],
  user: any,
}

//定义injection key
export const key: InjectionKey<Store<State>> = Symbol('store')

export const store = createStore<State>({
  plugins: [createPersistedState()],
  state() {
    return {
      isCollapse: false,
      menus: [] as IMenu[],
      user: null as ({ token: string } & IUserInfo) | null,
    }
  },
  mutations: {
    setMenus(state, payload) {
      state.menus = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
  },
})

export function useStore() {
  return baseUseStore(key)
}


